import ArrowTop from './asset/icon/arrowtop'
import ArrowBottom from './asset/icon/arrowbotton'
import Dice1 from './asset/img/dice/1.png'
import Dice2 from './asset/img/dice/2.png'
import Dice3 from './asset/img/dice/3.png'
import Dice4 from './asset/img/dice/4.png'
import Dice5 from './asset/img/dice/5.png'
import Dice6 from './asset/img/dice/6.png'
import { useEffect, useState } from "react";
export default function Controller({values , setvalues , setlastPredict, lastPredict }){
    const [DiceNumber , setDiceNumber]=useState({
        number1 : 1,
        number2 : 1
    });
    const [Dicestatus , setDicestatus] = useState({})
    const [Dicestatus2 , setDicestatus2] = useState({})


    const DiceInfo = [
        {
            number : 1 ,
            img : Dice1
        },
        {
            number : 2 ,
            img : Dice2
        },
        {
            number : 3 ,
            img : Dice3
        },
        {
            number : 4 ,
            img : Dice4
        },
        {
            number : 5 ,
            img : Dice5
        },
        {
            number : 6 ,
            img : Dice6
        }
    ];

    
    const DiceNumberhandller = (e)=>{ 
        const id = e.target.id
        if(id === '1'){
            const num = DiceNumber.number1+1
            if(DiceNumber.number1 < 6){
                setDiceNumber({
                    ...DiceNumber,
                    number1 : num
                })
                setDicestatus(DiceInfo[num - 1]);
                setvalues(prev => {return{
                    ...prev ,
                    number1 : num
                }})
               
            }else{
                setDiceNumber({
                    ...DiceNumber,
                    number1 : 6
                })
                setvalues(prev => {return{
                    ...prev ,
                    number1 : 6
                }})
            }
        }else if(id === "2"){
           
            const num = DiceNumber.number1-1
            if(DiceNumber.number1 > 1){
                setDiceNumber({
                    ...DiceNumber,
                    number1 : num
                })
                setDicestatus(DiceInfo[num - 1]);
                setvalues(prev => {return{
                    ...prev ,
                    number1 : num
                }})
                
            }else{
                setDiceNumber({
                    ...DiceNumber,
                    number1 : 1
                })
                setvalues(prev => {return{
                    ...prev ,
                    number1 : 1
                }})
            }
        }else if(id === "3"){
            const num = DiceNumber.number2+1
            if(DiceNumber.number2 < 6){
                setDiceNumber({
                    ...DiceNumber,
                    number2 : num
                })
                setDicestatus2(DiceInfo[num - 1]);
                setvalues(prev => {return{
                    ...prev ,
                    number2 : num
                }})
            }else{
                setDiceNumber({
                    ...DiceNumber,
                    number2 : 6
                })
                setvalues(prev => {return{
                    ...prev ,
                    number2 : 6
                }})
            }
        }else if(id === "4"){
            const num = DiceNumber.number2-1
            if(DiceNumber.number2 > 1){
                setDiceNumber({
                    ...DiceNumber,
                    number2 : num
                })
                setDicestatus2(DiceInfo[num - 1]);
                setvalues(prev => {return{
                    ...prev ,
                    number2 : num
                }})
            }else{
                setDiceNumber({
                    ...DiceNumber,
                    number2 : 1
                })
                setvalues(prev => {return{
                    ...prev ,
                    number2 : 1
                }})
            }
        }
        
    }

    useEffect(()=>{
        if(lastPredict.diceNumber1 !== null && lastPredict.diceNumber2 !== null ){
            setDicestatus(DiceInfo[lastPredict.diceNumber1 - 1]);
            setDicestatus2(DiceInfo[lastPredict.diceNumber2 - 1]);
            setvalues({
                number1 : lastPredict.diceNumber1 ,
                number2 : lastPredict.diceNumber2
            })
            setDiceNumber({
                number1 : lastPredict.diceNumber1 ,
                number2 : lastPredict.diceNumber2
            })
            console.log(values)
        }else{
            setDicestatus(DiceInfo[0]);
            setDicestatus2(DiceInfo[0]); 
        }
    },[lastPredict]) 

    return(
        <div className="flex justify-center items-center">
            <div className="flex flex-col justify-center items-center mr-7">
                <div className="mr-[5px] relative" >
                    <ArrowTop/>
                    <span onClick={DiceNumberhandller} id="1" className="w-full h-full absolute top-0"></span>
                </div>
                <div className="flex ml-1">
                    <img className="w-[120px]" src={Dicestatus.img} alt=""/>
                </div>
                <div className="relative" >
                    <ArrowBottom />
                    <span onClick={DiceNumberhandller} id="2" className="w-full h-full absolute top-0"></span>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className=" mr-[5px] relative">
                    <ArrowTop />
                    <span onClick={DiceNumberhandller} id="3" className="w-full h-full absolute top-0"></span>
                </div>
                <div className="flex ml-1">
                    <img className="w-[120px]" src={Dicestatus2.img} alt=""/>
                </div>
                <div className="relative">
                    <ArrowBottom />
                    <span onClick={DiceNumberhandller} id="4" className="w-full h-full absolute top-0"></span>
                </div>
            </div>
        </div>
    )
}