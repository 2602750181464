const url = 'https://app.dicemaniacs.com/api'
export const ProjectApi = [
    {
        countDown : url+'/count-down/',
        endEvent : url+'/end-event/',
        winners : url+'/winners/',
    },
    {
        postAuth : url+'/auth/',
        postConnectWallet : url+'/connect-wallet/',
        player : url+'/player/',
    },
    {
        predict : url+'/predict/',
    }
]