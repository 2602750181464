import axios from "axios";
import { ProjectApi } from "../api/Api";

export default function Button({ text, icon, className ,values ,controller }) {
  const PostDicehandler =async()=>{
    if(controller === 'disable'){
      console.log('error');
    }else if(controller === 'submit' || 'change'){
      try{
        const response = await axios.post(ProjectApi[2].predict ,{
          dice_number1 : values.number1 ,
          dice_number2 : values.number2
        },{headers : {
            'Authorization' : 'token 2'
        }});
        console.log(response)
      }catch(err){
          console.log(err)
      }
    }
  }

  return (
    <div
      className={`${className} w-[140px] h-[43px] bg-black/10 rounded-[117px] border  px-[20px] flex justify-between items-center`}
      onClick={PostDicehandler}
    >
      <span>{icon}</span>
      <span>{text}</span>
    </div>
  );
}
