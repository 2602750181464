import { useEffect, useState } from "react";
import "../../index.css";
import AddIcon from "../asset/icon/add";
import ConnectWallet from "../connectwallet";
import Controller from "../controller";
import Button from "../button";
import RepeatIcon from "../asset/icon/repeat";
import WinnerIcon from "../asset/icon/winericon";
import WinerPage from "../winerpage";
import JetIcon from "../asset/icon/jet";
import axios from "axios";
import { ProjectApi } from "../../api/Api";
import DisableIcon from "../asset/icon/disable";

const color = [
    {
      text: "SUBMIT",
      icon: <JetIcon />,
      className: "text-[#3BFFFF] border-[#3BFFFF] shadow-[0px_0px_8px_0px_rgba(59,255,255,1.00)]",
    },
    {
      text: "CHANGE",
      icon: <RepeatIcon />,
      className: "text-[#cafd7b] border-[#cafd7b] shadow-[0px_0px_8px_0px_rgba(202,253,123,1)]",
    },
    {
      text: "SUBMIT",
      icon: <DisableIcon />,
      className: "bg-[#454545]/60 shadow-[0px_0px_4px_0px_rgba(0,240,255,0.13)] border-[#454545] text-neutral-50/10",
    },
  ];
export default function Home() {
  const [controller, setcontroller] = useState('submit');
  const [openModal, setopenModal] = useState(false);
  const [time , settime]=useState({});
  const [finish,setfinish]=useState('')
  const [values , setvalues]=useState({
    number1 : 1,
    number2 : 1
  })
  const [countdown , setcountdown]=useState('');
  const [auth , setauth] = useState('');
  const [lastPredict , setlastPredict]=useState({
    diceNumber1 : null,
    diceNumber2 : null
  });
  

  const modalHandler = () => {
      openModal ? setopenModal(false) : setopenModal(true);
  };

  const BTNhandler =async() =>{
      try{
        const response =await axios.get(ProjectApi[2].predict , {headers :{
          'Authorization' : auth
        }})
        const number1 = response.data.dice_number1
        // get last predict 
        if(response.data.dice_number1 !== null && response.data.dice_number2 !== null){
          setlastPredict({
            diceNumber1 : response.data.dice_number1,
            diceNumber2 : response.data.dice_number2
          })
        }else{
          setlastPredict({
            diceNumber1 : 1,
            diceNumber2 : 1
          })
        }
        console.log(lastPredict)

        if(finish){
          setcontroller('disable');
        }else if(number1 === null){
          setcontroller('submit');
        }else if(number1 !== null){
          setcontroller('change');
        }
      }catch(err){
        console.log(err.message)
      }
  }
  
  const timeHandler = async () => {
    try {
      const response = await axios.get(ProjectApi[0].countDown);
      const date = new Date(response.data.expire_dt);
      setcountdown(getFormattedDate(date))
      setfinish(response.data.is_finished);
      startCountdown()
      const calculateRemainingTime = () => {
        const now = new Date();
        const diff = date - now; 
  
        if (diff <= 0) {
            axios.get(ProjectApi[0].endEvent);
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
  
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  
        return { days, hours, minutes, seconds };
      };
  
      const remainingTime = calculateRemainingTime();
      settime(remainingTime) 
    } catch (error) {
      console.error(error);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }; 
    }
  }; 

  function getFormattedDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear(); 

    return `${year}/${month}/${day}`;
}

  const startCountdown = () => {
    const interval = setInterval(() => {
        settime((prevTime) => {
        let { days, hours, minutes, seconds } = prevTime;

        if (
          days === 0 &&
          hours === 0 &&
          minutes === 0 &&
          seconds === 0
        ) {
          clearInterval(interval);
          console.log("Countdown finished!");
          return prevTime; 
        }

       
        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          seconds = 59;
          minutes -= 1;
        } else if (hours > 0) {
          seconds = 59;
          minutes = 59;
          hours -= 1;
        } else if (days > 0) {
          seconds = 59;
          minutes = 59;
          hours = 23;
          days -= 1;
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);
  };

  const telegram =async()=>{
    try{
    const tg = window.Telegram.WebApp ;
    tg.expand()
    const userData = tg.initDataUnsafe?.user || null
    console.log(userData);
    if(userData){
      const response = await axios.post(ProjectApi[1].postAuth , {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ telegram_data: userData }),
      });
      // set auth in response data
      setauth(response.data.player_id);
    }
    }catch(err){
      console.log(err)
    }
  }
  const formatNumber = (num) => (num < 10 ? `0${num}` : num);

  useEffect(()=>{
    timeHandler();
    BTNhandler();
    telegram();
  },[auth])

  return (
    <div className="home-layout">
      {openModal ? (
        <WinerPage modalHandler={modalHandler} countdown={countdown} />
      ) : (
        <div>
          <div className="text-center text-white text-xl font-medium] my-7">
            <p>GUESS THE NEXT DICE COMBINATION</p>
            <p>
              <span className="text-[#00efff]">EARN 10$</span> IN YOUR WALLET
            </p>
          </div>
          <div className="w-[170px] mx-auto">
            <ConnectWallet auth={auth}/>
          </div>
          <div className="my-8">
            <div className="w-[330px] h-[65px] flex justify-between items-center px-3 mx-auto rounded-[15px] shadow-[inset_0px_4px_26.600000381469727px_-7px_rgba(26,229,161,1.00)] border border-[#1ae5a1] ">
              <div className="mx-1">
                <AddIcon />
              </div>
              <p className="text-[#1ae5a1] text-[10px] font-semibold px-1">
                JOIN DICE MANIACS CHANNEL TO WATCH LATEST COMBINATIONS
              </p>
              <button className="w-[110px] h-7  rounded-lg shadow-[inset_0px_4px_4px_0px_rgba(255,234,234,0.00)] border border-[#1ae5a1] justify-center items-center inline-flex text-white text-[10px] font-medium">
                JOIN NOW
              </button>
            </div>
          </div>
          <div className="text-center mb-8">
            <p className="text-white text-xs font-medium">
              NEXT DICE COMBINATION WILL REVEAL IN :
            </p>
          </div>
          <div className="flex justify-evenly items-center">
            <div className="text-center">
              <p className="text-[#3bffff] text-xs font-medium">DAYS</p>
              <p className="text-white text-2xl font-medium">{formatNumber(time.days)}</p>
            </div>
            <div className="text-center">
              <p className="text-[#3bffff] text-xs font-medium">HOURS</p>
              <p className="text-white text-2xl font-medium">{formatNumber(time.hours)}</p>
            </div>
            <div className="text-center">
              <p className="text-[#3bffff] text-xs font-medium">MINUTES</p>
              <p className="text-white text-2xl font-medium">{formatNumber(time.minutes)}</p>
            </div>
            <div className="text-center">
              <p className="text-[#3bffff] text-xs font-medium">SECONDS</p>
              <p className="text-white text-2xl font-medium">{formatNumber(time.seconds)}</p>
            </div>
          </div>
          <div className="my-9">
            <Controller lastPredict={lastPredict} setlastPredict={setlastPredict} values={values} setvalues={setvalues}/>
          </div>
          <div className="flex justify-center relative h-20">
            {controller === 'submit' && <Button auth={auth} {...color[0]} controller={controller} values={values}/>}
            {controller === 'change' && <Button auth={auth} {...color[1]} controller={controller} values={values}/>}
            {controller === 'disable' && <Button auth={auth} {...color[2]} controller={controller} values={values}/>}

            <div onClick={modalHandler} className=" absolute right-[10px]">
              <WinnerIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
