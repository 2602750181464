import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import './index.css'
import { TonConnectUIProvider } from '@tonconnect/ui-react';
ReactDOM.render(
  <TonConnectUIProvider manifestUrl="https://longlegs.group/asset/tonconnect-manifest.json"> 
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </TonConnectUIProvider>,
  
  document.getElementById("root")
);
