import { TonConnectButton, useTonWallet } from "@tonconnect/ui-react";
import axios from "axios";
import { useEffect } from "react";
import { ProjectApi } from "../api/Api";

export default function ConnectWallet({auth}) {
    const wallet = useTonWallet();

    const sendAddress = async (walletAddress) => {
        if (!walletAddress) return;
        try {
            const response = await axios.post(
                ProjectApi[1].postConnectWallet,
                { wallet_address: walletAddress },
                {
                    headers: {
                        Authorization: auth,
                    },
                }
            );
            console.log("Address sent successfully:", response.data);
        } catch (err) {
            console.error("Error sending address:", err);
        }
    };

    useEffect(() => {
        const walletAddress = wallet?.account?.publicKey;
        sendAddress(walletAddress);
    }, [wallet?.account?.publicKey , auth]);

    return (
        <div>
            <TonConnectButton />
        </div>
    );
}
