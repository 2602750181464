import axios from "axios";
import { ProjectApi } from "../api/Api";
import { useState } from "react";
import Swal from 'sweetalert2'
export default function Button({ text, icon, className ,values ,controller , auth}) {
  const [loading , setloadin]=useState(false);
  const PostDicehandler =async()=>{
    if(controller === 'disable'){
      setloadin(true)
      console.log('error');
    }else if(controller === 'submit' || 'change'){
      try{
        setloadin(true)
        const response = await axios.post(ProjectApi[2].predict ,{
          dice_number1 : values.number1 ,
          dice_number2 : values.number2
        },{headers : {
            'Authorization' : auth
        }});
        console.log(response)
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Send dice number successfull",
          showConfirmButton: false,
          timer: 2000
        });
        setloadin(false)
        
      }catch(err){
          console.log(err)
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Try Again",
            showConfirmButton: false,
            timer: 1500
          });
          
      }
    }
  }

  return (
    <button
      disabled={loading}
      className={`${className} w-[140px] h-[43px] bg-black/10 rounded-[117px] border  px-[20px] flex justify-between items-center`}
      onClick={PostDicehandler}
    >
      <span>{icon}</span>
      <span>{text}</span>
    </button>
  );
}
